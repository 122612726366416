import React, { useEffect, useRef, useState } from 'react';
import AuthenticateLogin1 from '../../../asset/img/AuthenticateLogin1.png';
import indianFlag from '../../../asset/img/indianFlag.png';
import OTPLogin from '../../../asset/img/OTPLogin.png';
import onboarding_location_icon from '../../../asset/img/onboarding-location-icon.png';
import { apipost } from '../../../service/API';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../service/AuthContext';
import Loader from '../../../view/Component/Layout/Loader';
import axios from 'axios';
import { getCart } from '../../../service/CommanAPI';
// import { encrypt } from '../../../helpers/JWTToken';

export default function AuthModal({ getWishList, getNotifications }) {
    const { updateToken, updateLoginUserName, isLoggedIn, isBackEndLogin, updateUserRoleType, getCustomerType, updateCartDetails, updateCartCount } = useAuth();

    const [Mobile_Phone_No, setMobilePhoneNo] = useState('');
    const [otp, setOtp] = useState(['', '', '', '', '', '']);

    const [timer, setTimer] = useState(30); 
    const [otpSent, setOtpSent] = useState(false); 

    const navigate = useNavigate();
    const inputRefs = useRef([]);
    
    const [errors, setErrors] = useState({});
    const [otpError, setOtpError] = useState('');
    const [credentialErrors, setCredentialErrors] = useState('');
    const [location, setLocation] = useState(null);
    const [address, setAddress] = useState('');
    const [addHandleLoginLoader, setAddhandleLoginLoader] = useState(false);
    const [addHandleVerifyOtpLoader, setHandleVerifyOtpLoader] = useState(false);
 
    // Login API
    const handleLogin = async (e) => {
        setAddhandleLoginLoader(true);
        try {
            e.preventDefault();
            let data = {
                Mobile_Phone_No: Mobile_Phone_No,
            };
            const result = await apipost('login', data);
            if (result && result.status === false) {
                toast.error(result.message);
            }
            if (result && result.type === "credential_error" && result.status === false) {
                //Handle errors
                setErrors({});
                setCredentialErrors(result.message);
            }
            if (result && result.errors && result.errors.length > 0 && result.status === false) {
                const errorObject = {};
                result.errors.forEach(err => {
                    if (err.field === "Mobile_Phone_No") {
                        errors.Mobile_Phone_No = err.message;
                    }
                    errorObject[err.field] = err.message;
                });
                //Handle errors
                setCredentialErrors('');
                setErrors(errorObject);
            } else {
                if (result && result.data && result.data.status === true) {
                    toast.success(result.data.message);

                    window.$("#loginModal").modal("hide");
                    window.$("#otpverificationModal").modal("show");

                    // Start Timer For Resend OTP
                    startTimer();

                    //Handle errors
                    setCredentialErrors('');
                    setErrors({});
                }
            }
        } catch (error) {
            
        } finally{
            setOtp(['', '', '', '', '', '']);
            setOtpError('')
            setAddhandleLoginLoader(false);
        }
    };

    // Verify Otp API
    const handleVerifyOtp = async (e) => {
        setHandleVerifyOtpLoader(true);
        try {
            e.preventDefault();
            const otpValue = otp.join('');
            let data = {
                otp: otpValue,
                Mobile_Phone_No: Mobile_Phone_No,
            };
            const result = await apipost('verify-otp', data);
            if (result && result.status === false) {
                setOtpError(result.message);
            }
            if (result && result.data && result.data.status === true) {
                if (result.data.data.role_type === "Frontend") {
                    localStorage.clear();
                    // Encrypt Token
                    // let token = '';
                    // if (process.env.REACT_APP_CONSOLE === 'production') {
                    //     // token = encrypt(result.data.data.token, process.env.REACT_APP_SECRET_KEY);
                    //     token = btoa(result.data.data.token);
                    // } else {
                    //     token = result.data.data.token;
                    // }
                    localStorage.setItem('token', result.data.data.token);
                    localStorage.setItem('loginUserName', result.data.data.Name);
                    localStorage.setItem('role_type', result.data.data.role_type);
                    localStorage.setItem('loginUserPhoneNo', result.data.data.Mobile_Phone_No);
                    localStorage.setItem('isLoggedIn', 'true');

                    updateToken(result.data.data.token);
                    updateLoginUserName(result.data.data.Name);
                    isLoggedIn('true');
                    navigate('/customer-list');
                    updateUserRoleType('Frontend');
                    window.$("#otpverificationModal").modal("hide");
                } else {
                    if (result?.data?.data?.role_type) {
                        localStorage.setItem('role_type', result.data.data.role_type);
                    }
                    // Encrypt Token
                    // let token = '';
                    // if (process.env.REACT_APP_CONSOLE === 'production') {
                    //     // token = encrypt(result.data.data.token, process.env.REACT_APP_SECRET_KEY);
                    //     token = btoa(result.data.data.token);
                    // } else {
                    //     token = result.data.data.token;
                    // }
                    localStorage.setItem('token', result.data.data.token);
                    localStorage.setItem('loginUserName', result.data.data.Name);
                    localStorage.setItem('loginUserPhoneNo', result.data.data.Mobile_Phone_No);
                    localStorage.setItem('loginUserPhoneNo', result.data.data.Mobile_Phone_No);
                    localStorage.setItem('customer_type', result.data.data.customer_type);
                    localStorage.setItem('isLoggedIn', 'true');
        
                    getCustomerType(result.data.data.customer_type);
                    updateToken(result.data.data.token);
                    updateLoginUserName(result.data.data.Name);
                    isLoggedIn('true');
                    isBackEndLogin('true');
        
                    toast.success(result.data.message);
        
                    // Reset Field
                    setMobilePhoneNo('');
                    setOtp(['', '', '', '', '', '']);
                    setErrors({});
                    setOtpError('');
                    // Close Modal
                    window.$("#otpverificationModal").modal("hide");
                    window.$("#locationModal").modal("show");
                    // Call Necessary API
                    getCart(updateCartDetails, updateCartCount);
                    getWishList();
                    getNotifications();
                }
            }
        } catch (error) {
            
        } finally {
            setHandleVerifyOtpLoader(false);
        }
    };

    // Resend OTP API
    const handleResendOTP = async (e) => {
        e.preventDefault();
        let data = {
            Mobile_Phone_No: Mobile_Phone_No,
        };
        const result = await apipost('resend-otp', data);
        if (result && result.errors && result.errors.length > 0) {
            const errorObject = {};
            result.errors.forEach(err => {
                if (err.field === "Mobile_Phone_No") {
                    errors.Mobile_Phone_No = err.message;
                }
                errorObject[err.field] = err.message;
            });
            setErrors(errorObject);
        }

        if (result.data.status === true) {
            // Start Timer For Resend OTP
            startTimer();

            toast.success(result.data.message);
        }
    };

    // Function to start the timer for resend OTP
    const startTimer = () => {
        setTimer(30); // Initial timer value in seconds (30 seconds)
        setOtpSent(true); // Mark OTP as sent
    };

    useEffect(() => {
        let interval;
        if (otpSent && timer > 0) {
        interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [otpSent, timer]);

    // Format the remaining time as minutes and seconds
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        if(value && index < otp.length - 1)
        {
            inputRefs.current[index + 1].focus();
        }

        if (value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
        }
    };
    
    const handleKeyDown = (e, index) => {
        if(e.key === "Backspace" && index > 0 && !otp[index]){
            const newOtp = [...otp];
            newOtp[index - 1]= "";
            inputRefs.current[index - 1].focus();
            setOtp(newOtp);
        }
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                setLocation({ latitude, longitude });
                window.$("#locationModal").modal("hide");
                window.$("#allowLocationModal").modal("show");
            });
        } else {
            toast.error("Geolocation is not supported by this browser.");
        }
    };
    

    const confirmLocation = () => {
        localStorage.setItem('confirmedLocation', JSON.stringify(address));
        window.$("#allowLocationModal").modal("hide");
    };

    const fetchAddressFromCoordinates = async (latitude, longitude) => {
        const apiKey = 'pk.d119fe06513c4b4bac8b7505e84f9f16';
        const url = `https://us1.locationiq.com/v1/reverse.php?key=${apiKey}&lat=${latitude}&lon=${longitude}&format=json`;
    
        try {
            const response = await axios.get(url);
            setAddress(response.data.address);
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    useEffect(() => {
        if (location) {
            fetchAddressFromCoordinates(location.latitude, location.longitude);
        }
    }, [location]);
    
    return (
        <React.Fragment>
            {/* <!-- Login Modal--> */}
            <div className="modal fade" id="loginModal" tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
                <header className="mobile-back-header">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link className="btn-backarrow" data-bs-dismiss="modal" aria-label="Close"><i
                                    className="bi bi-chevron-left"></i></Link>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                    <div className="modal-content mobile-modal-content-full">
                        {/* <button type="button" className="btn-close mobile-hide" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        <Link type="button" className="close text-end mr-1 mobile-hide" style={{fontSize: '25px', color:'black'}} data-bs-dismiss="modal" aria-label="Close">X</Link>
                        <form onSubmit={handleLogin}>
                            <div className="modal-body">
                                <img src={AuthenticateLogin1} width="160" height="160" className="mb-3" alt='Authenticate-Login'/>
                                <div className="form-group">
                                    <h2 className="Heading-Small">Enter Mobile Number </h2>
                                    <p className="text-cool-gray">OTP will be sent to this number for verification</p>
                                </div>
                                <div className="form-group mb-6rem">
                                    <span className="icon-flag"><img src={indianFlag} width="32" alt='Indian-Flag'/> +91</span>
                                    <input type="text" value={Mobile_Phone_No} inputMode="numeric" pattern="[0-9]*" onChange={(e) => setMobilePhoneNo(e.target.value)} className="form-control mobile-control" maxLength={10} />
                                    {errors.Mobile_Phone_No && <span className="text-danger"> {errors.Mobile_Phone_No} </span>}
                                    {credentialErrors && <span className="text-danger"> {credentialErrors} </span>}
                                </div>
                            </div>
                            <div className="modal-footer">
                                {addHandleLoginLoader === true ? (
                                    <Loader spinner={'button-spinner'} className={'btn btn-primary w-100 pe-1 ps-1 me-2'} width={'100%'}/>    
                                ) : (
                                    <button type="submit" className="btn btn-primary w-100">Continue</button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!--/ Login Modal--> */}

            {/* <!-- OTP Modal--> */}
            <div className="modal fade" id="otpverificationModal" tabIndex="-1" aria-labelledby="otpverificationModalLabel"
                aria-hidden="true">
                <header className="mobile-back-header">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link className="btn-backarrow" data-bs-dismiss="modal" aria-label="Close"><i
                                className="bi bi-chevron-left"></i></Link>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                    <div className="modal-content mobile-modal-content-full">
                        {/* <button type="button" className="btn-close mobile-hide" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        
                        <Link type="button" className="close text-end mr-1 mobile-hide" style={{fontSize: '25px', color:'black'}} data-bs-dismiss="modal" aria-label="Close">X</Link>
                        <form onSubmit={handleVerifyOtp}>
                            <div className="modal-body">
                                <img src={OTPLogin} width="160" height="160" className="mb-3" alt='OTPLogin'/>
                                <div className="form-group">
                                    <h2 className="Heading-Small">Enter verification code </h2>
                                    <p className="text-cool-gray">6 digit OTP has been sent to +91 {Mobile_Phone_No}</p>
                                </div>
                                <div className="form-group">
                                    <div className="row align-items-center">
                                        {otp && otp.map((value, index) => (
                                            <div className="col-2" key={index}>
                                                <input
                                                    type="text"
                                                    className="form-control text-center" inputMode="numeric" pattern="[0-9]*" 
                                                    value={value}
                                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                                    onChange={(e) => handleChange(e, index)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                    placeholder="0"
                                                    maxLength="1"
                                                />
                                            </div>
                                        ))}
                                        {otpError && <span className="text-danger"> {otpError} </span>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <p><strong><span id="countdowntimer">{otpSent && timer > 0 && formatTime(timer)} </span></strong></p>
                                    {otpSent && timer === 0 && <p>Didn't receive the code?<Link onClick={handleResendOTP}>Resend now</Link></p>}
                                </div>
                            </div> 
                            <div className="modal-footer">
                                <div className="form-group">
                                    <div className="row">
                                        {addHandleVerifyOtpLoader === true ? (
                                            <Loader spinner={'button-spinner'} className={'btn btn-primary w-100 pe-1 ps-1 me-2'} width={'100%'}/>    
                                        ) : (
                                            <button type="submit" className="btn btn-primary w-100">Verify</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!--/ OTP Modal--> */}

            {/* <!-- Password  Modal--> */}
            <div className="modal fade" id="passwordModal" tabIndex="-1" aria-labelledby="passwordModalLabel" aria-hidden="true">
                <header className="mobile-back-header">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Link className="btn-backarrow" data-bs-dismiss="modal" aria-label="Close"><i
                                className="bi bi-chevron-left"></i></Link>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                    <div className="modal-content mobile-modal-content-full">
                        {/* <button type="button" className="btn-close mobile-hide" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        <Link type="button" className="close text-end mr-1 mobile-hide" data-bs-dismiss="modal" style={{fontSize: '20px', color:'black'}} aria-label="Close">X</Link>
                        <form>
                            <div className="modal-body mb-6rem">
                                <img src="/../../../asset/img/PasswordLogin1.png" width="160" height="160" className="mb-3" alt='Password-Login'/>
                                <div className="form-group mb-5">
                                    <h2 className="Heading-Small">Enter your password </h2>
                                </div>
                                <div className="form-group">
                                    <span className="input-icon-right text-theme"><i className="bi bi-eye"></i></span>
                                    <input type="password" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <p>Forgot password? <Link>Recover account</Link></p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mob-mb1">
                                        <Link className="btn btn-outline-primary w-100" data-bs-target="#otpverificationModal"
                                            data-bs-toggle="modal" data-bs-dismiss="modal">Login with OTP</Link>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mob-mb1">
                                        <button type="submit" className="btn btn-primary w-100">Verify</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!-- / Password Modal-- > */}

            {/* <!-- Location  Modal--> */}
            <div className="modal fade" id="locationModal" tabIndex="-1" aria-labelledby="locationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                    <div className="modal-content mobile-modal-content-full">
                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        
                        <Link type="button" className="close text-end mr-1 mobile-hide" style={{fontSize: '25px', color:'black'}} data-bs-dismiss="modal" aria-label="Close">X</Link>
                        {/* <form> */}
                        <div className="modal-body text-center">
                            <img src={onboarding_location_icon} width="160" height="160" className="mb-5" alt='onboarding-location'/>
                            <div className="form-group mb-5">
                                <h3>Allow location access</h3>
                                <p className="text-cool-gray">We will use it to help you set up your restaurant location</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mob-mb1">
                                    <Link className="btn btn-outline-primary w-100" data-bs-target="#allowLocationManual"
                                        data-bs-toggle="modal" data-bs-dismiss="modal">Enter Manual</Link>
                                </div>
                                <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mob-mb1">
                                    <button type="submit" className="btn btn-primary w-100" onClick={getLocation}>Allow</button>
                                </div>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
            {/* <!--/ Location Modal--> */}

            {/* <!-- Location  Modal--> */}
            <div className="modal fade" id="allowLocationModal" tabIndex="-1" aria-labelledby="allowLocationModal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                    <div className="modal-content mobile-modal-content-full">
                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        
                        <Link type="button" className="close text-end mr-1" data-bs-dismiss="modal" style={{fontSize: '20px', color:'black'}} aria-label="Close">X</Link>

                        <div className="modal-body p-0 text-center">
                            <div className="form-group text-start mb-3 p-3">
                                <h3 className="mb-0">Outlet Location</h3>
                            </div>
                            <div className="form-group">
                                <iframe width="100%"  height="400" src={location && `https://maps.google.com/maps?q=${location ? `${location.latitude},${location.longitude}` : '0,0'}&hl=es;z=14&output=embed`} frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" title="Outlet-Location"
                                ></iframe>
                            </div>

                            <div className="form-group p-2">
                                <h5>{address.road}</h5>
                                <p className="text-cool-gray">{address.road},{address.county}, {address.state_district}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary w-100" onClick={confirmLocation} data-bs-dismiss="modal">Confirm location</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="allowLocationManual" tabIndex="-1" aria-labelledby="allowLocationManual"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                    <div className="modal-content mobile-modal-content-full">
                        <Link type="button" className="close text-end mr-1" data-bs-dismiss="modal" style={{fontSize: '20px', color:'black'}} aria-label="Close">X</Link>
                        {/* <form> */}
                        <div className="modal-body p-0 text-center">
                            <div className="form-group text-start mb-3 p-3">
                                <h3 className="mb-0">Outlet Location</h3>
                            </div>
                            <div className="form-group">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.4186244858656!2d75.36875697499859!3d19.86453758150927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdba3e8bc8150d7%3A0x4bb6f57353286b92!2sLokshahi%20Colony!5e0!3m2!1sen!2sin!4v1716627460691!5m2!1sen!2sin" width="100%" height="350" style={{ border: 0 }} allowFullScreen="" loading="lazy" title="Outlet-Location" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <div className="form-group p-2">
                                <h5>lokshahi Colony</h5>
                                <p className="text-cool-gray">HPCL Housing Colony, N 2, Cidco, Aurangabad</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary w-100" data-bs-dismiss="modal" aria-label="Close">Confirm location</button>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
            {/* <!--/ Location Modal--> */}
        </React.Fragment>
    )
}
