import { apiget } from "./API";
import { toast } from "react-toastify";

export const checkUserToken = async () => {
    // Logout If Token Expire
    localStorage.clear();
    window.location.replace('/');
}

// Get Wishlist
export const getWishList = async (updateWishListDetails = () => {}) => {
    const result = await apiget('wishlist/get-wishlist');
    // API Error While Get Wishlist
    if (result?.data?.status === false) {
        toast.error(result.data.message);
    }
    // Get Wishlist Successfully
    if (result?.data?.status === true) {
        updateWishListDetails(result.data.data);
    }
};

// Get Cart
export const getCart = async (updateCartDetails = () => {}, updateCartCount = () => {}) => {
    const result = await apiget('cart/get-cart');
    // API Error While Get Cart
    if (result?.data?.status === false) {
        toast.error(result.data.message);
    }
    // Get Cart Successfully
    if (result?.data?.status === true) {
        updateCartDetails(result.data.data);
        updateCartCount(result.data.data.total_items || 0);
    }
};