import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { apipost } from '../../../service/API';
import { toast } from 'react-toastify';
import { useAuth } from '../../../service/AuthContext';
import AuthModal from './AuthModal';
import Loader from './Loader';
import $ from 'jquery';
import { getCart, getWishList } from '../../../service/CommanAPI';

export default function Header({ getNotifications, notifications }) {
  const { token, updateToken, updateLoginUserName, isLoggedIn, isBackEndLogin, loginUserName, updateUserTokenValidate, appLogo, deviceType, customerType, cartCount, getWishListItems, updateUserRoleType, updateCartDetails, updateCartCount, updateWishListDetails, userRoleType } = useAuth();

  const [getLocation, setGetLocation] = useState('');
  const getPhoneNo = localStorage.getItem('loginUserPhoneNo');
  const [getUpdateDate, setUpdateDate] = useState('Custom');
  const [getMonthStartDate, setMonthStartDate] = useState('');
  const [getMonthEndDate, setMonthEndDate] = useState('');
  const [getSearchItem, setGetSearchItem] = useState('');
  const [getCheckbox, setCheckbox] = useState('false');
  const hasFetchedGetCart = useRef(false);
  const location = useLocation();
  const timeoutRef = useRef(null);
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const handleViewAllNotifications = (show_data) => {
    if(show_data === 'hide') {
      setShowAllNotifications(false);
    } else {
      setShowAllNotifications(true);
    }
  };
  
  useEffect(() => {
    // Call getCart And getWishList Only When User Refresh Page
    if(token) {
      if (!hasFetchedGetCart.current) {
        getCart(updateCartDetails, updateCartCount);
        getWishList(updateWishListDetails);
        hasFetchedGetCart.current = true;
      }
    }
}, [getWishList, updateWishListDetails, updateCartDetails, updateCartCount]);

  const setDate = async (date_type) => {
    const today = new Date();

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    if (date_type === 'Last Month') {
      const startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const endDate = new Date(today.getFullYear(), today.getMonth(), 0);

      setMonthStartDate(formatDate(startDate));
      setMonthEndDate(formatDate(endDate));
      setUpdateDate(date_type);
    } else if (date_type === 'Current Month') {
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const endDate = today;

      setMonthStartDate(formatDate(startDate));
      setMonthEndDate(formatDate(endDate));
      setUpdateDate(date_type);
    } else if (date_type === 'Last Quarter') {
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      let startMonth, endMonth, startYear, endYear;

      if (currentMonth <= 2) {
        // Current quarter is Q1, so last quarter is Q4 of the previous year
        startMonth = 9; // October
        endMonth = 11; // December
        startYear = endYear = currentYear - 1;
      } else if (currentMonth <= 5) {
        // Current quarter is Q2, so last quarter is Q1 of the current year
        startMonth = 0; // January
        endMonth = 2; // March
        startYear = endYear = currentYear;
      } else if (currentMonth <= 8) {
        // Current quarter is Q3, so last quarter is Q2 of the current year
        startMonth = 3; // April
        endMonth = 5; // June
        startYear = endYear = currentYear;
      } else {
        // Current quarter is Q4, so last quarter is Q3 of the current year
        startMonth = 6; // July
        endMonth = 8; // September
        startYear = endYear = currentYear;
      }

      const startDate = new Date(startYear, startMonth, 1);
      const endDate = new Date(endYear, endMonth + 1, 0);

      setMonthStartDate(formatDate(startDate));
      setMonthEndDate(formatDate(endDate));
      setUpdateDate(date_type);
    } else if (date_type === 'Current Quarter') {
      const startMonth = Math.floor(today.getMonth() / 3) * 3;
      const startDate = new Date(today.getFullYear(), startMonth, 1);
      const endDate = today;

      setMonthStartDate(formatDate(startDate));
      setMonthEndDate(formatDate(endDate));
      setUpdateDate(date_type);
    } else if (date_type === 'F.Y 24-25') {
      const startDates = '2024-04-01';
      const endDates = formatDate(today);
      
      // Parse the date strings into Date objects (assumes DD-MM-YYYY format)
      const [startYear, startMonth, startDay] = startDates.split('-').map(Number);
      const [endYear, endMonth, endDay] = endDates.split('-').map(Number);
      
      const startDate = new Date(startYear, startMonth - 1, startDay);
      const endDate = new Date(endYear, endMonth - 1, endDay);
      
      setMonthStartDate(formatDate(startDate));
      setMonthEndDate(formatDate(endDate));
      setUpdateDate(date_type);
    } else {
      setUpdateDate(date_type);
    }
  };

  useEffect(() => {
    setGetLocation(JSON.parse(localStorage.getItem('confirmedLocation')) || {})
    const accountDrawer = document.querySelector('#accountDrawer');
    if (accountDrawer) {
      accountDrawer.click();
    }
  }, [location.pathname]);

  // Logout API
  const handleLogout = async () => {
    try {
      const result = await apipost('logout');
      // API Error While Logout
      if (result?.data?.message && result?.data?.status === false) {
        toast.error(result.data.message);
      }
      // Logout Successfully
      if (result?.data?.status === true) {
        setTimeout(() => {
          navigate("/");
        }, 100);
        // Clear Loacal Storage
        localStorage.clear();
    
        // Update Custome Hook Variable
        updateToken(localStorage.removeItem('token'));
        isLoggedIn(localStorage.removeItem('isLoggedIn'));
        updateLoginUserName(localStorage.removeItem('loginUserName'));
        isBackEndLogin(localStorage.removeItem('isBackEndLogin'));
      }
    } catch (error) {
      console.error(`Error while logout: ${error}`);
    }
  };

  const setBackCustomer = async () => {
    // localStorage.removeItem('token');
    const customer_token = localStorage.getItem('customer_token');
    const customer_login_name = localStorage.getItem('customerLoginName')
    const customer_phone_no = localStorage.getItem('customerPhoneNo')
    localStorage.setItem('token', customer_token);
    localStorage.setItem('loginUserName', customer_login_name);
    localStorage.setItem('loginUserPhoneNo', customer_phone_no);
    localStorage.setItem('role_type', 'Frontend');
    localStorage.removeItem('customer_token');
    localStorage.removeItem('customerLoginName');
    localStorage.removeItem('customerPhoneNo');

    updateUserRoleType('Frontend');
    navigate("/customer-list");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setGetSearchItem('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const searchitem = useCallback(async (searchItem) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(async () => {
      let data = {
        Item_Name: searchItem === '' ? '' : searchItem
      };
      const result = await apipost('item/search-item', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setGetSearchItem("");
      }
      if (result && result.status === false) {
        setGetSearchItem("");
      }
      if (result && result.data && result.data.status === true) {
        setGetSearchItem(result.data.data);
      }
    }, 500);
  }, [updateUserTokenValidate]);

  useEffect(() => {
    $('#search-item').val('');
    setGetSearchItem('');
  }, [location.pathname]);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountStatementLoader, setAccountStatementLoader] = useState(false);
  const [accountStatementWhatsappLoader, setAccountStatementWhatsappLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const downloadAccountStatement = async (send_to_whatsapp) => {
    if (send_to_whatsapp === 'send_to_whatsapp') {
      setAccountStatementWhatsappLoader(true);
    } else {
      setAccountStatementLoader(true);
    }
    
    let data = {
      start_date: getUpdateDate === 'Custom' ? startDate : getMonthStartDate,
      end_date: getUpdateDate === 'Custom' ? endDate : getMonthEndDate,
      send_to_whatsapp: send_to_whatsapp,
      customer_whatsapp_numbers: getCheckbox === true 
      ? `${localStorage.getItem("customerPhoneNo")},${localStorage.getItem("loginUserPhoneNo")}` 
      : localStorage.getItem("customerPhoneNo") ? localStorage.getItem("customerPhoneNo") : localStorage.getItem("loginUserPhoneNo"),
    };
    
    const result = await apipost('customer/get-account-statement', data);
    if (result && result.status === false && result.errors && result.errors.length > 0) {
      const errorObject = {};
      result.errors.forEach(err => {
        errorObject[err.field] = err.message;
      });
      setAccountStatementLoader(false);
      setAccountStatementWhatsappLoader(false);
      setErrors(errorObject);
    }
    if (result && result.status === false && !result.errors) {
      const error = { account_statement: result.message };

      toast.error(errors.account_statement);
      setAccountStatementLoader(false);
      setAccountStatementWhatsappLoader(false);
      setErrors(error);
    }
    if (result && result.data && result.data.status === true) {
      setErrors({});
      const linkSource = `data:application/pdf;base64,${result.data.data}`;
      const downloadLink = document.createElement("a");
      const fileName = "account_statement.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setStartDate('');
      setEndDate('');
      setAccountStatementLoader(false);
      setAccountStatementWhatsappLoader(false);

      toast.success(result.data.message);
    }
  };

  return (
    <React.Fragment>
      {!token && (
        <React.Fragment>
          <header className={deviceType === 'mobile' ? location.pathname === "/" ? "header-fixed" : "mobile-hide" : "header-fixed"}>
            <nav className="nabar">
              <div className="container-fluid">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-4">
                  <Link to="/" className="web-white-logo">
                    <img src={appLogo ? appLogo : "/../../../asset/img/no_image_available.jpg"} alt='app-logo'
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/../../../asset/img/no_image_available.jpg";
                      e.target.style.height = '65px';
                      e.target.style.width = '100px';
                    }} />
                  </Link>
                  <Link to="/" className="web-black-logo mb-2">
                    <img src={appLogo ? appLogo : "/../../../asset/img/no_image_available.jpg"} alt='app-logo'
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/../../../asset/img/no_image_available.jpg";
                        e.target.style.height = '65px';
                        e.target.style.width = '100px';
                      }} />
                  </Link>
                  </div>
                  <div className="col-lg-10 col-xl-10 col-md-8 col-sm-8 col-8 text-end">
                    <Link className="btn btn-primary btn-rounded12" data-bs-toggle="modal" data-bs-target="#loginModal">Login/Signup</Link>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </React.Fragment>
      )}

      {token && userRoleType !== 'Frontend' && (
        <React.Fragment>
          <header className={deviceType === 'mobile' && location.pathname === "/" ? "login-header" : "login-header mobile-hide"}>
            <nav className="nabar">
              <div className="container-fluid">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-lg-4 col-xl-4 col-12 mobile-hide">
                    <div className="row align-items-center">
                      <div className="col-lg-5 col-xl-5 col-md-12 col-sm-12 col-12">
                        <Link to="/">
                          <img className="app-logo" src={appLogo ? appLogo : "/../../../asset/img/no_image_available.jpg"} alt='app-logo'
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/../../../asset/img/no_image_available.jpg";
                              e.target.style.height = '65px';
                              e.target.style.width = '100px';
                            }} />
                        </Link>
                      </div>
                      <div className="col-lg-7 col-xl-7 col-12">
                        <p className="text-elipsis mb-0">{getLocation.road && getLocation.road + `,`}{getLocation.county && getLocation.county + `,`} {getLocation.state_district && getLocation.state_district + `,`}{getLocation.state && getLocation.state}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-xl-8 col-12">
                    <div className="row align-items-center">
                      <div className="col-lg-8 col-xl-8 col-8" ref={headerRef}>
                        <div className="form-group mb-0">
                          <span className="icon-flag">
                            <img src="/../../../asset/img/searchThick.png" width="18" alt="search-icon" />
                          </span>
                          <input
                            type="text"
                            className="form-control search-bar"
                            placeholder="Search your need..."
                            onChange={(e) => searchitem(e.target.value)}
                            id="search-item"
                          />
                          {getSearchItem && getSearchItem.length > 0 && (
                            <div className='search-results'>
                              <ul>
                                {getSearchItem.map((get_search_item, index) => (
                                  <Link to={`/item-details/${get_search_item.SystemId}`} className="text-decoration-none" key={index} onClick={() => searchitem('hideResults')}>
                                    <li className="search-item">
                                      <div className="search-item-content">
                                        <img src={get_search_item.image ? get_search_item.image : '/../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '/../asset/img/no_image_available.jpg'; }} width="60px" alt={get_search_item.image}/>
                                        <span>{get_search_item.Description}</span>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-4 col-4 text-end">
                        <nav>
                          <ul className="iconNavigation">
                            {localStorage.getItem('customer_token') && localStorage.getItem('role_type') === "frontend" && (
                              <li>
                                <span onClick={() => setBackCustomer()}>
                                  <img src='/../../../asset/img/Arrow-left.png' className="Heading-icon" height='65px' alt='arrow-left'/>
                                </span>
                              </li>
                            )}
                            <li>
                              <Link to="/my-cart" className="active"><i className="bi bi-cart2"></i>
                                <div className="cart-counter">{cartCount && cartCount}</div>
                              </Link>
                            </li>
                            {token && (
                              getWishListItems.total_wishlist > 0 ? (
                                <li className="mobile-hide">
                                  <Link to="/my-wishlist" className="active"><i className="bi bi-heart-fill"></i>
                                    <div className="cart-counter" style={{ color: "white" }}>
                                      {getWishListItems ? getWishListItems.total_wishlist : 0}
                                    </div>
                                  </Link>
                                </li>
                              ) : (
                                <li className="mobile-hide">
                                  <Link to="/my-wishlist"><i className="bi bi-heart"></i>
                                    <div className="cart-counter" style={{ color: "white" }}>
                                    {getWishListItems ? getWishListItems.total_wishlist : 0}
                                    </div>
                                  </Link>
                                </li>
                              )
                            )}
                            <li className="mobile-hide">
                              <Link data-bs-toggle="offcanvas" data-bs-target="#notificationDrawer" aria-controls="notificationDrawer"><i className="bi bi-bell"></i></Link>
                            </li>
                            <li className="mobile-hide">
                              <Link data-bs-toggle="offcanvas" data-bs-target="#accountDrawer" aria-controls="accountDrawer"><i className="bi bi-filter-right"></i></Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>

          {/* <!--offcanvas right--> */}
          <div className="offcanvas offcanvas-end" data-bs-scroll="true" tabIndex="-1" id="accountDrawer" aria-labelledby="accountDrawerLabel">
            <div className="offcanvas-header">
              <div className="col-lg-auto col-xl-auto col-2">
                <div className="avtar42">
                  <img src="/../../../asset/img/sidemenu_user_icon.png" alt='sidemenu-icon'/>
                </div>
              </div>
              <div className="col-10">
                <h5 className="offcanvas-title" id="accountDrawerLabel">{loginUserName}</h5>
                <p className="mb-0 text-muted">+91 {getPhoneNo}</p>
              </div>
              <Link type="button" className="close text-end mr-1 text-reset" style={{ fontSize: '20px', color: 'black', marginLeft: deviceType === 'mobile' ? '-15px' : '' }} data-bs-dismiss="offcanvas" aria-label="Close">X</Link>
            </div>
            <div className="offcanvas-body">
              {(customerType !== 'Retailer') && (
                <React.Fragment>
                  <div className="side-widget">
                    <p className="widget-title">ORDERS & STATEMENTS</p>
                    <ul className="widget-nav">
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link to="/order/my-order">
                          <div className="row align-items-center">
                            <div className="col-8 p-0">
                              <img src="/../../../asset/img/sidemenu_orders_v2.png" width="16" className="me-2" alt='sidemenu-orders'/>
                              Your orders
                            </div>
                            <div className="col-4 p-0 text-end">
                              <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link to="/address">
                          <div className="row align-items-center">
                            <div className="col-8 p-0">
                              <img src="/../../../asset/img/sidemenu_orders_v2.png" width="16" className="me-2" alt='sidemenu-orders'/>
                              Address
                            </div>
                            <div className="col-4 p-0 text-end">
                              <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link data-bs-toggle="modal" data-bs-target="#exampleModal">
                          <div className="row align-items-center">
                            <div className="col-8 p-0">
                              <img src="/../../../asset/img/sidemenu_hp_wallet_icon_v2.png" width="16" className="me-2" alt='account-statement'/>
                              Account Statement
                            </div>
                            <div className="col-4 p-0 text-end">
                              <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon' />
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="side-widget">
                    <p className="widget-title">WALLET & PAYMENT</p>
                    <ul className="widget-nav">
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link to="/sales-invoice">
                          <div className="row align-items-center">
                            <div className="col-8 p-0">
                              <img src="/../../../asset/img/sidemenu_hp_wallet_icon_v2.png" width="16" className="me-2" alt='sales-invoice'/>
                              Sales Invoice
                            </div>
                            <div className="col-4 p-0 text-end">
                              <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link to="/sales-payment">
                          <div className="row align-items-center">
                            <div className="col-8 p-0">
                              <img src="/../../../asset/img/sidemenu_hp_wallet_icon_v2.png" width="16" className="me-2" alt='sales-payment'/>
                              Sales Payment
                            </div>
                            <div className="col-4 p-0 text-end">
                              <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </React.Fragment>
              )}

              <div className="side-widget">
                <p className="widget-title">OTHERS</p>
                <ul className="widget-nav">
                    <React.Fragment>
                    {(customerType === 'Distributor' || customerType === 'Dealer') && (
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link to="/list-retailer">
                          <div className="row align-items-center">
                            <div className="col-8 p-0">
                              <img src="/../../../asset/img/sidemenu_profile_v2.png" width="16" className="me-2" alt='retailer'/>
                              Retailer
                            </div>
                            <div className="col-4 p-0 text-end">
                              <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                            </div>
                          </div>
                        </Link>
                      </li>
                      )}
                      {(customerType === 'Distributor' || customerType === 'Dealer' || customerType === 'Retailer') && (
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link to="/list-electrician">
                          <div className="row align-items-center">
                            <div className="col-8 p-0">
                              <img src="/../../../asset/img/sidemenu_profile_v2.png" width="16" className="me-2" alt='electrician'/>
                              Electrician
                            </div>
                            <div className="col-4 p-0 text-end">
                              <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                            </div>
                          </div>
                        </Link>
                      </li>
                    )}
                  </React.Fragment>

                  <li data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/profile-setting">
                      <div className="row align-items-center">
                        <div className="col-8 p-0">
                          <img src="/../../../asset/img/sidemenu_profile_v2.png" width="16" className="me-2" alt='profile-settings'/>
                          Profile Settings
                        </div>
                        <div className="col-4 p-0 text-end">
                          <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li data-bs-toggle="offcanvas" data-bs-target="#notificationDrawer" aria-controls="notificationDrawer">
                    <Link>
                      <div className="row align-items-center">
                        <div className="col-8 p-0">
                          <img src="/../../../asset/img/sidemenu-notification.png" width="16" className="me-2" alt='notification'/>
                          Notification
                        </div>
                        <div className="col-4 p-0 text-end">
                          <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/request-product">
                      <div className="row align-items-center">
                        <div className="col-8 p-0">
                          <img src="/../../../asset/img/sidemenu-request-order.png" width="16" className="me-2" alt='request-new-item' />
                          Request New Item
                        </div>
                        <div className="col-4 p-0 text-end">
                          <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/contact-us">
                      <div className="row align-items-center">
                        <div className="col-8 p-0">
                          <img src="/../../../asset/img/side-menu-info.png" width="16" className="me-2" alt='contact-us'/>
                          Contact us
                        </div>
                        <div className="col-4 p-0 text-end">
                          <img src="/../../../asset/img/sidemenu_side_icon.png" width="8" alt='sidemenu-icon'/>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="side-widget">
                <ul className="widget-nav">
                  <li onClick={handleLogout} data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link>
                      <div className="row align-items-center">
                        <div className="col-12 p-0">
                          <img src="/../../../asset/img/sidemenu_logout_v2.png" width="16" className="me-2" alt='logout-icon'/>
                          <span className="text-theme">Logout</span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="notificationDrawer" aria-labelledby="notificationDrawerLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="accountDrawerLabel">Notifications</h5>
              <Link type="button" className="close text-end mr-1 text-reset" data-bs-dismiss="offcanvas" style={{ fontSize: '20px', color: 'black' }} aria-label="Close">X</Link>
            </div>
            <div className="offcanvas-body">
              <ul className="notification-side-list">
                {notifications && notifications.length > 0 ? (
                  (showAllNotifications ? notifications : notifications.slice(0, 10)).map((get_notifications) => {
                    const created_at = new Date(get_notifications.created_at);
                    const now = new Date();
                    const timeDifferenceInDays = Math.floor((now - created_at) / (1000 * 60 * 60 * 24));

                    return (
                      <li key={get_notifications.id}>
                        <Link>
                          <div className="row align-items-center">
                            <div className="col-2">
                              <div className="notification-icon42">
                                <img src="/../../../asset/img/notification-placeholder-img.png" alt="notification"/>
                              </div>
                            </div>
                            <div className="col-10">
                              <p><strong>{get_notifications.title}</strong></p>
                              <p className="p-12">{get_notifications.description}</p>
                              <p className="p-12 text-muted">
                                {timeDifferenceInDays === 0
                                  ? `${Math.floor((now - created_at) / (1000 * 60 * 60))} hours ago`
                                  : `${timeDifferenceInDays} days ago`}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })
                ) : (
                  <li>No Notifications Found.</li>
                )}
                {notifications && notifications.length > 10 && (
                  showAllNotifications === false ? (
                    <p className="mt-2"><Link href="#" className="text-muted" onClick={()=> handleViewAllNotifications('show')}>View All Notifications</Link></p>
                  ) : (
                    <p className="mt-2"><Link href="#" className="text-muted" onClick={()=> handleViewAllNotifications('hide')}>Hide Notifications</Link></p>
                  )
                )}
              </ul>
            </div>
          </div>

        </React.Fragment >
      )
      }

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Choose accounting period</h5>
              <Link type="button" className="close text-end mr-1 text-reset" data-bs-dismiss="modal" style={{ fontSize: '20px', color: 'black' }} aria-label="Close">X</Link>
            </div>
            <div className="modal-body">
              <ul className="accountTypeList">
                <li><Link className={getUpdateDate === 'Custom' ? 'active' : "text-dark"} onClick={() => setDate('Custom')}>Custom</Link></li>
                <li><Link className={getUpdateDate === 'Last Month' ? 'active' : "text-dark"} onClick={() => setDate('Last Month')}>Last Month</Link></li>
                <li><Link className={getUpdateDate === 'Current Month' ? 'active' : "text-dark"} onClick={() => setDate('Current Month')}>Current Month</Link></li>
                <li><Link className={getUpdateDate === 'Last Quarter' ? 'active' : "text-dark"} onClick={() => setDate('Last Quarter')}>Last Quarter</Link></li>
                <li><Link className={getUpdateDate === 'Current Quarter' ? 'active' : "text-dark"} onClick={() => setDate('Current Quarter')}>Current Quarter</Link></li>
                <li><Link className={getUpdateDate === 'F.Y 24-25' ? 'active' : "text-dark"} onClick={() => setDate('F.Y 24-25')}>F.Y 24-25</Link></li>
              </ul>
              <h6><strong>Select date</strong></h6>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Start Date</label>
                    <input type="date" className="form-control" value={getUpdateDate === 'Custom' ? startDate : getMonthStartDate} onChange={(e) => setStartDate(e.target.value)} max={new Date().toISOString().split('T')[0]} id="startDate" placeholder="Start Date" disabled={getUpdateDate !== 'Custom'} />
                    {errors && errors.start_date && <span className="text-danger"> {errors && errors.start_date} </span>}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>End Date</label>
                    <input type="date" className="form-control" value={getUpdateDate === 'Custom' ? endDate : getMonthEndDate} onChange={(e) => setEndDate(e.target.value)} max={new Date().toISOString().split('T')[0]} id="endDate" placeholder="End Date" disabled={getUpdateDate !== 'Custom'} />
                    {errors && errors.end_date && <span className="text-danger"> {errors && errors.end_date} </span>}
                  </div>
                </div>
              </div>
              {localStorage.getItem('customer_token') && localStorage.getItem('role_type') === "frontend" && (
                <React.Fragment>
                <input className="form-check-input" type="checkbox" onClick={(e) => setCheckbox(e.target.checked)} id="flexCheckDefault"/>
                <label className="ms-2">Send To Customer</label></React.Fragment>
              )}
            </div>
            <div className="modal-footer">
              <div className="row">
                {accountStatementWhatsappLoader === true ? (
                  <div className="col-12 col-md-6">
                    <Loader spinner={'account-stsatement'} className={'col-6 btn btn-primary w-100 div button'} />
                  </div>
                ) : (
                  <div className="col-12 col-md-6">
                    <button type="button" className="btn btn-primary w-100" onClick={() => downloadAccountStatement('send_to_whatsapp')}>Send Whatsapp</button>
                  </div>
                )}
                {accountStatementLoader === true ? (
                  <div className="col-6">
                    <Loader spinner={'account-stsatement'} className={'col-6 btn btn-outline-primary w-100 div button'} />
                  </div>
                ) : (
                  <div className="col-6 mobile-hide desktop-show">
                    <button type="button" className="btn btn-outline-primary w-100" onClick={() => downloadAccountStatement('')}>Download</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AuthModal getWishList={getWishList} getNotifications={getNotifications} />
    </React.Fragment >
  )
}
